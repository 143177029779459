.shipmentTrackingPreviewModal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(8px);
  -moz-backdrop-filter: blur(8px);
  -o-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  z-index: 55;
  overflow-y: auto;
  -webkit-transition: visibility 0.2s, opacity 0.2s ease-in-out;
  -moz-transition: visibility 0.2s, opacity 0.2s ease-in-out;
  -o-transition: visibility 0.2s, opacity 0.2s ease-in-out;
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
}

.shipmentTrackingPreviewModal > .close {
  position: fixed;
  top: 2rem;
  right: 3rem;
  color: #fff;
  font-size: 2rem;
  font-style: normal;
  cursor: pointer;
}

.shipmentTrackingPreviewModalOpen {
  visibility: visible !important;
  opacity: 1 !important;
}

.shipmentTracking {
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  z-index: 55;
}

.shipmentTracking > .states {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 5;
}

.shipmentTracking > .states > .state {
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.shipmentTracking > .states > .state > .progress {
  position: relative;
  margin-top: 3rem;
  background-color: transparent;
  width: 100%;
  height: auto;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -o-border-radius: 2rem;
  border-radius: 2rem;
  -webkit-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  -moz-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  -o-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
}

.shipmentTracking > .states > .state > .progress > h1 {
  background-color: var(--pttYellowColor);
  padding: 0.8rem 0;
  -webkit-border-radius: 2rem 2rem 0 0;
  -moz-border-radius: 2rem 2rem 0 0;
  -o-border-radius: 2rem 2rem 0 0;
  border-radius: 2rem 2rem 0 0;
  color: var(--pttLinkColor);
  text-align: center;
}

.shipmentTracking > .states > .state > .progress > h2 {
  background-color: #fff;
  padding: 1rem 0 0 0;
  color: var(--pttLinkColor);
  font-size: 0.8rem;
  text-align: center;
}

.shipmentTracking > .states > .state > .progress > .panel {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: nowrap;
  flex-direction: row;
  background-color: #fff;
  padding: 1rem 0 1rem 0;
  overflow: hidden;
}

.shipmentTracking > .states > .state > .progress > .panel > div {
  background-color: #fff;
  width: 100%;
  padding: 1rem;
}

.shipmentTracking > .states > .state > .progress > .panel > div:last-child {
  border-left: solid 2px #f1f1f1;
}

.shipmentTracking > .states > .state > .progress > .panel > div > p {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0;
}

.shipmentTracking > .states > .state > .progress > .panel > div > p:last-child {
  margin: 0;
}

.shipmentTracking > .states > .state > .progress > .panel > div > p > i {
  width: 3rem;
  height: 3rem;
  color: var(--pttLinkColor);
  font-size: 1.4rem;
  text-align: center;
  line-height: 3rem;
}

.shipmentTracking > .states > .state > .progress > .panel > div > p > label {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: nowrap;
  flex-direction: column;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .panel
  > div
  > p
  > label
  > span:first-child {
  margin-bottom: 0.3rem;
  color: var(--pttLinkColor);
  font-size: 0.7rem;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .panel
  > div
  > p
  > label
  > span:last-child {
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
}

.shipmentTracking > .states > .state > .progress > .deliveryInformation {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 28%;
  left: -5%;
  background-color: #fff;
  padding: 0 1rem;
  width: 70%;
  height: auto;
  -webkit-border-radius: 0 0 0 2rem;
  -moz-border-radius: 0 0 0 2rem;
  -o-border-radius: 0 0 0 2rem;
  border-radius: 0 0 0 2rem;
  -webkit-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  -moz-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  -o-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  z-index: -1;
  -webkit-transition: left 0.3s ease-in-out;
  -moz-transition: left 0.3s ease-in-out;
  -o-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
  -webkit-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
  -moz-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
  -o-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
  animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .deliveryInformation
  > .body {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  width: 100%;
  height: 80%;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .deliveryInformation
  > .body
  > div {
  margin-bottom: 1rem;
  padding: 1rem;
  width: 50%;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .deliveryInformation
  > .body
  > div
  > h6 {
  margin-bottom: 0.2rem;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .deliveryInformation
  > .body
  > div
  > h6
  > i {
  margin-right: 0.2rem;
  border-bottom: solid 1px #f1f1f1;
  color: var(--pttLinkColor);
  font-size: 0.8rem;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .deliveryInformation
  > .body
  > div
  > h6
  > span {
  border-bottom: solid 1px #f1f1f1;
  color: var(--pttLinkColor);
  font-size: 0.7rem;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .deliveryInformation
  > .body
  > div
  > span {
  color: var(--pttLinkColor);
  font-size: 0.8rem;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .deliveryInformationOpenButton {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
  position: absolute;
  top: 15%;
  left: -5%;
  background-color: var(--pttLinkColor);
  width: 11rem;
  height: 2.5rem;
  padding-left: 1rem;
  -webkit-border-radius: 2rem 0 0 0;
  -moz-border-radius: 2rem 0 0 0;
  -o-border-radius: 2rem 0 0 0;
  border-radius: 2rem 0 0 0;
  color: #fff;
  -webkit-transition: left 0.3s ease-in-out;
  -moz-transition: left 0.3s ease-in-out;
  -o-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
  z-index: 1;
  cursor: pointer;
  -webkit-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
  -moz-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
  -o-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
  animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .deliveryInformationOpenButton
  > .arrow {
  position: absolute;
  right: -0.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%) scaleX(1);
  -moz-transform: translateY(-50%) scaleX(1);
  -o-transform: translateY(-50%) scaleX(1);
  transform: translateY(-50%) scaleX(1);
  border-top: solid 1rem transparent;
  border-right: solid 1.25rem #ddd;
  border-left: solid 1.25rem transparent;
  border-bottom: solid 1rem transparent;
  transition: transform 0.5s, right 0.5s, border 1s ease-in-out;
  z-index: 1;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .deliveryInformationOpenButton
  > .arrowOpen {
  right: -3rem;
  border-right: solid 1.25rem #ddd;
  -webkit-transform: translateY(-50%) scaleX(-1);
  -moz-transform: translateY(-50%) scaleX(-1);
  -o-transform: translateY(-50%) scaleX(-1);
  transform: translateY(-50%) scaleX(-1);
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .deliveryInformationOpenButton
  > .arrowBack {
  position: absolute;
  right: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background-color: #555;
  width: 0.5rem;
  height: 2rem;
}

.shipmentTracking > .states > .state > .progress > .deliveryInformationActive {
  left: -70%;
}

.shipmentTracking > .states > .state > .progress > .fees {
  position: absolute;
  top: 28%;
  right: -5%;
  background-color: #fff;
  padding: 0 1rem;
  width: 90%;
  height: auto;
  -webkit-border-radius: 0 0 2rem 0;
  -moz-border-radius: 0 0 2rem 0;
  -o-border-radius: 0 0 2rem 0;
  border-radius: 0 0 2rem 0;
  -webkit-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  -moz-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  -o-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  z-index: -1;
  -webkit-transition: right 0.3s ease-in-out;
  -moz-transition: right 0.3s ease-in-out;
  -o-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
  -webkit-animation: feesAnimation 1s ease 0s 2 normal none;
  -moz-animation: feesAnimation 1s ease 0s 2 normal none;
  -o-animation: feesAnimation 1s ease 0s 2 normal none;
  animation: feesAnimation 1s ease 0s 2 normal none;
}

.shipmentTracking > .states > .state > .progress > .fees > .body {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  width: 100%;
  height: 80%;
}

.shipmentTracking > .states > .state > .progress > .fees > .body > div {
  margin-bottom: 1rem;
  padding: 1rem;
  width: 50%;
}

.shipmentTracking > .states > .state > .progress > .fees > .body > div > h6 {
  margin-bottom: 0.2rem;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .fees
  > .body
  > div
  > h6
  > i {
  margin-right: 0.2rem;
  border-bottom: solid 1px #f1f1f1;
  color: var(--pttLinkColor);
  font-size: 0.8rem;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .fees
  > .body
  > div
  > h6
  > span {
  border-bottom: solid 1px #f1f1f1;
  color: var(--pttLinkColor);
  font-size: 0.7rem;
}

.shipmentTracking > .states > .state > .progress > .fees > .body > div > span {
  color: var(--pttLinkColor);
  font-size: 0.8rem;
}

.shipmentTracking > .states > .state > .progress > .feesOpenButton {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 15%;
  right: -5%;
  background-color: var(--pttLinkColor);
  width: 11rem;
  height: 2.5rem;
  padding-right: 1rem;
  -webkit-border-radius: 0 2rem 0 0;
  -moz-border-radius: 0 2rem 0 0;
  -o-border-radius: 0 2rem 0 0;
  border-radius: 0 2rem 0 0;
  color: #fff;
  -webkit-transition: right 0.3s ease-in-out;
  -moz-transition: right 0.3s ease-in-out;
  -o-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
  z-index: 1;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-animation: feesAnimation 1s ease 0s 2 normal none;
  -moz-animation: feesAnimation 1s ease 0s 2 normal none;
  -o-animation: feesAnimation 1s ease 0s 2 normal none;
  animation: feesAnimation 1s ease 0s 2 normal none;
}

.shipmentTracking > .states > .state > .progress > .feesOpenButton > .arrow {
  position: absolute;
  left: -0.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%) scaleX(1);
  -moz-transform: translateY(-50%) scaleX(1);
  -o-transform: translateY(-50%) scaleX(1);
  transform: translateY(-50%) scaleX(1);
  border-top: solid 1rem transparent;
  border-right: solid 1.25rem transparent;
  border-left: solid 1.25rem #ddd;
  border-bottom: solid 1rem transparent;
  -webkit-transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
  -moz-transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
  -o-transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
  transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
  z-index: 1;
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .feesOpenButton
  > .arrowOpen {
  left: -3rem;
  border-left: solid 1.25rem #ddd;
  -webkit-transform: translateY(-50%) scaleX(-1);
  -moz-transform: translateY(-50%) scaleX(-1);
  -o-transform: translateY(-50%) scaleX(-1);
  transform: translateY(-50%) scaleX(-1);
}

.shipmentTracking
  > .states
  > .state
  > .progress
  > .feesOpenButton
  > .arrowBack {
  position: absolute;
  left: -0.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  background-color: #555;
  width: 0.5rem;
  height: 2rem;
}

.shipmentTracking > .states > .state > .progress > .feesActive {
  right: -90%;
}

.shipmentTracking > .states > .state > .icons {
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 1;
}

.shipmentTracking > .states > .state > .icons::after {
  position: absolute;
  bottom: -3rem;
  background-color: transparent;
  content: "";
  border-top: solid 10px transparent;
  border-right: solid 10px transparent;
  border-left: solid 10px transparent;
  border-bottom: solid 10px var(--pttYellowColor);

  z-index: 0;
}

.shipmentTracking > .states > .state > .icons > .icon {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  min-width: 5rem;
  min-height: 5rem;
  margin-right: 2rem;
  border: solid 0.5rem var(--pttLinkColor);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.shipmentTracking > .states > .state > .icons > .icon .activeIcon {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -o-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -webkit-animation: activeIconBoxShadow 2s infinite;
  -moz-animation: activeIconBoxShadow 2s infinite;
  -o-animation: activeIconBoxShadow 2s infinite;
  animation: activeIconBoxShadow 2s infinite;
}

.shipmentTracking > .states > .state > .icons > .icon::after {
  position: absolute;
  right: -1.75rem;
  background-color: transparent;
  width: 0.5rem;
  height: 0.5rem;
  content: "";
  border-top: solid 5px rgba(221, 221, 221, 0.1);
  border-right: solid 5px rgba(221, 221, 221, 0.1);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 0;
}

.shipmentTracking > .states > .state > .icons > .icon:last-child {
  margin-right: 0;
}

.shipmentTracking > .states > .state > .icons > .icon > svg {
  fill: var(--pttLinkColor);
  width: 2rem;
}

.shipmentTracking
  > .states
  > .state
  > .icons
  > .icon:nth-child(odd)
  > .iconPath {
  position: absolute;
  top: 50%;
  left: 75%;
  background-color: transparent;
  border-top: none !important;
  -webkit-border-radius: 0 0 50% 50%;
  -moz-border-radius: 0 0 50% 50%;
  -o-border-radius: 0 0 50% 50%;
  border-radius: 0 0 50% 50%;
  content: "";
  width: 5rem;
  height: 4rem;
  z-index: -1;
}

.shipmentTracking
  > .states
  > .state
  > .icons
  > .icon:nth-child(even)
  > .iconPath {
  position: absolute;
  bottom: 50%;
  left: 75%;
  background-color: transparent;
  border-bottom: none !important;
  -webkit-border-radius: 50% 50% 0 0;
  -moz-border-radius: 50% 50% 0 0;
  -o-border-radius: 50% 50% 0 0;
  border-radius: 50% 50% 0 0;
  content: "";
  width: 5rem;
  height: 4rem;
  z-index: -1;
}

.shipmentTracking > .states > .state > .icons > .icon:last-child::before {
  display: none;
}

.shipmentTracking > .states > .state > .icons > .icon:last-child::after {
  display: none;
}

.shipmentTracking > .states > .state > .icons > .icon > .iconShadow {
  position: absolute;
  background-color: transparent;
  min-width: 5rem;
  min-height: 5rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 0 10px 25px -15px rgba(0, 0, 0, 1);
}

.shipmentTracking > .states > .history {
  background-color: #f1f1f1;
  -webkit-border-radius: 0 0 2rem 2rem;
  -moz-border-radius: 0 0 2rem 2rem;
  -o-border-radius: 0 0 2rem 2rem;
  border-radius: 0 0 2rem 2rem;
  -webkit-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  -moz-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  -o-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
  overflow: hidden;
  user-select: none;
  z-index: -1;
}

.shipmentTracking > .states > .history > ul {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  padding: 1rem 0;
  width: 100%;
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
}
.surveyButton {
  background-color: var(--pttYellowColor);
  margin-top: 1rem;
  height: 3rem;
  padding: 0 2rem;
  border: none;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  text-align: center;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -o-border-radius: 2rem;
  border-radius: 2rem;
}
.surveyButton > i {
  margin-right: 0.5rem;
}

.shipmentTracking > .states > .history > ul::-webkit-scrollbar {
  width: 0.7rem;
  height: 100px;
}

.shipmentTracking > .states > .history > ul::-webkit-scrollbar-track {
  background-color: #ddd;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -o-border-radius: 2rem;
  border-radius: 2rem;
}

.shipmentTracking > .states > .history > ul::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -o-border-radius: 2rem;
  border-radius: 2rem;
}

.shipmentTracking > .states > .history > ul > li {
  position: relative;
  margin-bottom: 1rem;
  width: 500px;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  border-radius: 0.5rem;
}

.shipmentTracking > .states > .history > ul > li > i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 1.4rem;
  font-size: 2rem;
}

.shipmentTracking > .states > .history > ul > li:last-child {
  margin-bottom: 1rem;
}

.shipmentTracking > .states > .history > ul > li:nth-child(odd) {
  padding-right: 5.5rem;
  padding-left: 0.5rem;
}

.shipmentTracking > .states > .history > ul > li:nth-child(even) {
  padding-right: 0.5rem;
  padding-left: 5.5rem;
}

.shipmentTracking > .states > .history > ul > li:nth-child(even) > i {
  left: 1.4rem;
}

.shipmentTracking > .states > .history > ul > li:nth-child(even) > p {
  text-align: right;
}

.shipmentTracking > .states > .history > ul > li:nth-child(even) > p > label {
  flex-direction: row-reverse;
}

.shipmentTracking > .states > .history > ul > li > p > label {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li
  > p
  > label
  > div:nth-child(1) {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 20%;
  padding: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  overflow: hidden;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li:nth-child(odd)
  > p
  > label
  > div:nth-child(1) {
  margin-right: 0.5rem;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li:nth-child(even)
  > p
  > label
  > div:nth-child(1) {
  margin-left: 0.5rem;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li
  > p
  > label
  > div:nth-child(1)
  > label {
  margin-bottom: 0.5rem;
  font-size: 0.7rem;
  font-weight: var(--fontWeight);
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li
  > p
  > label
  > div:nth-child(1)
  > span {
  font-size: 0.7rem;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li
  > p
  > label
  > div:nth-child(2) {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  flex-basis: 80%;
  background-color: #fff;
  padding: 0.5rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -o-border-radius: 2rem;
  border-radius: 0.5rem;
  color: var(--pttLinkColor);
  -webkit-box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
  -o-box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
  box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li
  > p
  > label
  > div:nth-child(2)
  > div:nth-child(1) {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: row;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li
  > p
  > label
  > div:nth-child(2)
  > div:nth-child(1)
  > div:first-child {
  margin-right: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 50%;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li:nth-child(odd)
  > p
  > label
  > div:nth-child(2)
  > div:nth-child(1)
  > div:first-child {
  border-right: solid 2px #eee;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li
  > p
  > label
  > div:nth-child(2)
  > div:nth-child(1)
  > div:last-child {
  margin-right: 0;
  flex-basis: 50%;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li:nth-child(even)
  > p
  > label
  > div:nth-child(2)
  > div:nth-child(1)
  > div:last-child {
  border-left: solid 2px #eee;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li
  > p
  > label
  > div:nth-child(2)
  > div:nth-child(1)
  > div
  > label {
  position: relative;
  display: block;
  font-size: 0.7rem;
  font-weight: var(--fontWeight);
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li
  > p
  > label
  > div:nth-child(2)
  > div:nth-child(1)
  > div
  > span {
  position: relative;
  display: block;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.7rem;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li
  > p
  > label
  > div:nth-child(2)
  > div:nth-child(2) {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
}

.shipmentTracking
  > .states
  > .history
  > ul
  > li
  > p
  > label
  > div:nth-child(2)
  > div:nth-child(2)
  > label {
  margin-top: 0.5rem;
  font-size: 0.7rem;
  font-weight: var(--fontWeight);
}

@-webkit-keyframes activeIconBoxShadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }

  100% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    -moz-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    -o-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}

@-moz-keyframes activeIconBoxShadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }

  100% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    -moz-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    -o-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}

@-o-keyframes activeIconBoxShadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }

  100% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    -moz-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    -o-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}

@keyframes activeIconBoxShadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }

  100% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    -moz-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    -o-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}

@-webkit-keyframes deliveryInformationAnimation {
  0% {
    left: -5%;
  }

  50% {
    left: -8%;
  }

  100% {
    left: -5%;
  }
}

@-moz-keyframes deliveryInformationAnimation {
  0% {
    left: -5%;
  }

  50% {
    left: -8%;
  }

  100% {
    left: -5%;
  }
}

@-o-keyframes deliveryInformationAnimation {
  0% {
    left: -5%;
  }

  50% {
    left: -8%;
  }

  100% {
    left: -5%;
  }
}

@keyframes deliveryInformationAnimation {
  0% {
    left: -5%;
  }

  50% {
    left: -8%;
  }

  100% {
    left: -5%;
  }
}

@-webkit-keyframes feesAnimation {
  0% {
    right: -5%;
  }

  50% {
    right: -8%;
  }

  100% {
    right: -5%;
  }
}

@-moz-keyframes feesAnimation {
  0% {
    right: -5%;
  }

  50% {
    right: -8%;
  }

  100% {
    right: -5%;
  }
}

@-o-keyframes feesAnimation {
  0% {
    right: -5%;
  }

  50% {
    right: -8%;
  }

  100% {
    right: -5%;
  }
}

@keyframes feesAnimation {
  0% {
    right: -5%;
  }

  50% {
    right: -8%;
  }

  100% {
    right: -5%;
  }
}

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  .shipmentTrackingPreviewModal {
    padding-top: 8rem;
  }

  .shipmentTrackingPreviewModal > .close {
    font-size: 2rem !important;
  }

  .shipmentTrackingPreviewModalOpen {
  }

  .shipmentTracking {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }

  .shipmentTracking > .states {
    position: relative;
    width: 85%;
  }

  .shipmentTracking > .states > .state {
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
  }

  .shipmentTracking > .states > .state > .icons {
    flex-direction: column !important;
  }

  .shipmentTracking > .states > .state > .icons::after {
    display: none;
  }

  .shipmentTracking > .states > .state > .icons > .icon {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .shipmentTracking > .states > .state > .icons > .icon .activeIcon {
  }

  .shipmentTracking > .states > .state > .icons > .icon::after {
    top: 4.9rem;
    right: 1.5rem;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .shipmentTracking > .states > .state > .icons > .icon:last-child {
    margin-right: 0;
  }

  .shipmentTracking > .states > .state > .icons > .icon > svg {
    fill: var(--pttLinkColor);
    width: 2rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .icons
    > .icon:nth-child(odd)
    > .iconPath {
    top: 90%;
    left: 30%;
    transform: rotate(-90deg);
  }

  .shipmentTracking
    > .states
    > .state
    > .icons
    > .icon:nth-child(even)
    > .iconPath {
    top: 90%;
    left: -50%;
    transform: rotate(-90deg);
  }

  .shipmentTracking > .states > .state > .icons > .icon:last-child::before {
  }

  .shipmentTracking > .states > .state > .icons > .icon:last-child::after {
  }

  .shipmentTracking > .states > .state > .icons > .icon > .iconShadow {
  }

  .shipmentTracking > .states > .state > .progress {
    position: relative;
    width: 66%;
    margin-top: 0;
    box-shadow: none;
  }

  .shipmentTracking > .states > .state > .progress::before {
    position: absolute;
    top: 100%;
    left: 2rem;
    background-color: var(--pttYellowColor);
    content: "";
    width: 0.5rem;
    height: 3rem;
    z-index: -1;
  }

  .shipmentTracking > .states > .state > .progress::after {
    position: absolute;
    top: 100%;
    right: 2rem;
    background-color: var(--pttYellowColor);
    content: "";
    width: 0.5rem;
    height: 3rem;
    z-index: -1;
  }

  .shipmentTracking > .states > .state > .progress > h1 {
  }

  .shipmentTracking > .states > .state > .progress > h2 {
  }

  .shipmentTracking > .states > .state > .progress > .panel {
    flex-wrap: wrap;
    flex-direction: column;
    border-radius: 0 0 2rem 2rem !important;
    padding: 0;
  }

  .shipmentTracking > .states > .state > .progress > .panel > div {
    position: relative;
    padding: 1rem;
    border-bottom: dashed 2px #ccc;
  }

  .shipmentTracking > .states > .state > .progress > .panel > div:last-child {
    border-bottom: none;
    border-left: none;
  }

  .shipmentTracking > .states > .state > .progress > .panel > div::before {
    position: absolute;
    left: -0.55rem;
    bottom: -0.6rem;
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    width: 1rem;
    height: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    z-index: 1;
  }

  .shipmentTracking > .states > .state > .progress > .panel > div::after {
    position: absolute;
    right: -0.55rem;
    bottom: -0.6rem;
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    width: 1rem;
    height: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    z-index: 1;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .panel
    > div:last-child::before {
    display: none !important;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .panel
    > div:last-child::after {
    display: none !important;
  }

  .shipmentTracking > .states > .state > .progress > .panel > div > p {
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .panel
    > div
    > p:last-child {
  }

  .shipmentTracking > .states > .state > .progress > .panel > div > p > i {
  }

  .shipmentTracking > .states > .state > .progress > .panel > div > p > label {
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .panel
    > div
    > p
    > label
    > span:first-child {
    font-size: 0.7rem !important;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .panel
    > div
    > p
    > label
    > span:last-child {
  }

  .shipmentTracking > .states > .state > .progress > .deliveryInformation {
    display: none;
    /* display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 28%;
    left: -5%;
    background-color: #fff;
    padding: 0 1rem;
    width: 70%;
    height: auto;
    -webkit-border-radius: 0 0 0 2rem;
    -moz-border-radius: 0 0 0 2rem;
    -o-border-radius: 0 0 0 2rem;
    border-radius: 0 0 0 2rem;
    -webkit-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    -o-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    z-index: -1;
    -webkit-transition: left 0.3s ease-in-out;
    -moz-transition: left 0.3s ease-in-out;
    -o-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
    -webkit-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    -moz-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    -o-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    animation: deliveryInformationAnimation 1s ease 0s 2 normal none; */
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    width: 100%;
    height: 80%;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body
    > div {
    margin-bottom: 1rem;
    padding: 1rem;
    width: 50%;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body
    > div
    > h6 {
    margin-bottom: 0.2rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body
    > div
    > h6
    > i {
    margin-right: 0.2rem;
    border-bottom: solid 1px #f1f1f1;
    color: var(--pttLinkColor);
    font-size: 0.8rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body
    > div
    > h6
    > span {
    border-bottom: solid 1px #f1f1f1;
    color: var(--pttLinkColor);
    font-size: 0.7rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body
    > div
    > span {
    color: var(--pttLinkColor);
    font-size: 0.8rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformationOpenButton {
    display: none;
    /* display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    align-items: center;
    position: absolute;
    top: 15%;
    left: -5%;
    background-color: var(--pttLinkColor);
    width: 11rem;
    height: 2.5rem;
    padding-left: 1rem;
    -webkit-border-radius: 2rem 0 0 0;
    -moz-border-radius: 2rem 0 0 0;
    -o-border-radius: 2rem 0 0 0;
    border-radius: 2rem 0 0 0;
    color: #fff;
    -webkit-transition: left 0.3s ease-in-out;
    -moz-transition: left 0.3s ease-in-out;
    -o-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
    z-index: 1;
    cursor: pointer;
    -webkit-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    -moz-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    -o-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    animation: deliveryInformationAnimation 1s ease 0s 2 normal none; */
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformationOpenButton
    > .arrow {
    position: absolute;
    right: -0.5rem;
    top: 50%;
    -webkit-transform: translateY(-50%) scaleX(1);
    -moz-transform: translateY(-50%) scaleX(1);
    -o-transform: translateY(-50%) scaleX(1);
    transform: translateY(-50%) scaleX(1);
    border-top: solid 1rem transparent;
    border-right: solid 1.25rem #ddd;
    border-left: solid 1.25rem transparent;
    border-bottom: solid 1rem transparent;
    transition: transform 0.5s, right 0.5s, border 1s ease-in-out;
    z-index: 1;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformationOpenButton
    > .arrowOpen {
    right: -3rem;
    border-right: solid 1.25rem #ddd;
    -webkit-transform: translateY(-50%) scaleX(-1);
    -moz-transform: translateY(-50%) scaleX(-1);
    -o-transform: translateY(-50%) scaleX(-1);
    transform: translateY(-50%) scaleX(-1);
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformationOpenButton
    > .arrowBack {
    position: absolute;
    right: -0.5rem;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    background-color: #555;
    width: 0.5rem;
    height: 2rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformationActive {
    left: -70%;
  }

  .shipmentTracking > .states > .state > .progress > .fees {
    display: none;
    /* position: absolute;
    top: 28%;
    right: -5%;
    background-color: #fff;
    padding: 0 1rem;
    width: 90%;
    height: auto;
    -webkit-border-radius: 0 0 2rem 0;
    -moz-border-radius: 0 0 2rem 0;
    -o-border-radius: 0 0 2rem 0;
    border-radius: 0 0 2rem 0;
    -webkit-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    -o-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    z-index: -1;
    -webkit-transition: right 0.3s ease-in-out;
    -moz-transition: right 0.3s ease-in-out;
    -o-transition: right 0.3s ease-in-out;
    transition: right 0.3s ease-in-out;
    -webkit-animation: feesAnimation 1s ease 0s 2 normal none;
    -moz-animation: feesAnimation 1s ease 0s 2 normal none;
    -o-animation: feesAnimation 1s ease 0s 2 normal none;
    animation: feesAnimation 1s ease 0s 2 normal none; */
  }

  .shipmentTracking > .states > .state > .progress > .fees > .body {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    width: 100%;
    height: 80%;
  }

  .shipmentTracking > .states > .state > .progress > .fees > .body > div {
    margin-bottom: 1rem;
    padding: 1rem;
    width: 50%;
  }

  .shipmentTracking > .states > .state > .progress > .fees > .body > div > h6 {
    margin-bottom: 0.2rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .fees
    > .body
    > div
    > h6
    > i {
    margin-right: 0.2rem;
    border-bottom: solid 1px #f1f1f1;
    color: var(--pttLinkColor);
    font-size: 0.8rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .fees
    > .body
    > div
    > h6
    > span {
    border-bottom: solid 1px #f1f1f1;
    color: var(--pttLinkColor);
    font-size: 0.7rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .fees
    > .body
    > div
    > span {
    color: var(--pttLinkColor);
    font-size: 0.8rem;
  }

  .shipmentTracking > .states > .state > .progress > .feesOpenButton {
    display: none;
    /* display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 15%;
    right: -5%;
    background-color: var(--pttLinkColor);
    width: 11rem;
    height: 2.5rem;
    padding-right: 1rem;
    -webkit-border-radius: 0 2rem 0 0;
    -moz-border-radius: 0 2rem 0 0;
    -o-border-radius: 0 2rem 0 0;
    border-radius: 0 2rem 0 0;
    color: #fff;
    -webkit-transition: right 0.3s ease-in-out;
    -moz-transition: right 0.3s ease-in-out;
    -o-transition: right 0.3s ease-in-out;
    transition: right 0.3s ease-in-out;
    z-index: 1;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-animation: feesAnimation 1s ease 0s 2 normal none;
    -moz-animation: feesAnimation 1s ease 0s 2 normal none;
    -o-animation: feesAnimation 1s ease 0s 2 normal none;
    animation: feesAnimation 1s ease 0s 2 normal none; */
  }

  .shipmentTracking > .states > .state > .progress > .feesOpenButton > .arrow {
    position: absolute;
    left: -0.5rem;
    top: 50%;
    -webkit-transform: translateY(-50%) scaleX(1);
    -moz-transform: translateY(-50%) scaleX(1);
    -o-transform: translateY(-50%) scaleX(1);
    transform: translateY(-50%) scaleX(1);
    border-top: solid 1rem transparent;
    border-right: solid 1.25rem transparent;
    border-left: solid 1.25rem #ddd;
    border-bottom: solid 1rem transparent;
    -webkit-transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
    -moz-transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
    -o-transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
    transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
    z-index: 1;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .feesOpenButton
    > .arrowOpen {
    left: -3rem;
    border-left: solid 1.25rem #ddd;
    -webkit-transform: translateY(-50%) scaleX(-1);
    -moz-transform: translateY(-50%) scaleX(-1);
    -o-transform: translateY(-50%) scaleX(-1);
    transform: translateY(-50%) scaleX(-1);
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .feesOpenButton
    > .arrowBack {
    position: absolute;
    left: -0.5rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "";
    background-color: #555;
    width: 0.5rem;
    height: 2rem;
  }

  .shipmentTracking > .states > .state > .progress > .feesActive {
    right: -90%;
  }

  .shipmentTracking > .states > .history {
    position: relative;
    border-top: solid 0.5rem var(--pttYellowColor);
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -o-border-radius: 2rem;
    border-radius: 2rem;
  }

  .shipmentTracking > .states > .history > ul {
    display: block;
    max-height: 200px;
    padding: 0;
  }

  .shipmentTracking > .states > .history > ul::-webkit-scrollbar {
  }

  .shipmentTracking > .states > .history > ul::-webkit-scrollbar-track {
  }

  .shipmentTracking > .states > .history > ul::-webkit-scrollbar-thumb {
  }

  .shipmentTracking > .states > .history > ul > li {
    background-color: #fff;
    width: 100%;
    margin-bottom: 0;
    padding: 1rem !important;
    border-bottom: dashed 2px #ccc;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }

  .shipmentTracking > .states > .history > ul > li > i {
    display: none;
  }

  .shipmentTracking > .states > .history > ul > li > p {
    overflow: hidden;
  }

  .shipmentTracking > .states > .history > ul > li:last-child {
  }

  .shipmentTracking > .states > .history > ul > li:nth-child(odd) {
    padding: 0 0.5rem;
  }

  .shipmentTracking > .states > .history > ul > li:nth-child(even) {
    padding: 0 0.5rem;
  }

  .shipmentTracking > .states > .history > ul > li:nth-child(even) > i {
  }

  .shipmentTracking > .states > .history > ul > li:nth-child(even) > p {
  }

  .shipmentTracking > .states > .history > ul > li:nth-child(even) > p > label {
  }

  .shipmentTracking > .states > .history > ul > li > p > label {
    align-items: normal;
    flex-wrap: wrap;
    border: 0.2rem dotted #dfdedde0;
    border-radius: 0.5rem;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(1) {
    flex-basis: 100%;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li:nth-child(odd)
    > p
    > label
    > div:nth-child(1) {
    margin-right: 0;
    -webkit-border-radius: 0 0.1rem 0.1rem 0;
    -moz-border-radius: 0 0.1rem 0.1rem 0;
    -o-border-radius: 0 0.1rem 0.1rem 0;
    border-radius: 0 0.1rem 0.1rem 0;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li:nth-child(even)
    > p
    > label
    > div:nth-child(1) {
    margin-left: 0;
    -webkit-border-radius: 0 0.1rem 0.1rem 0;
    -moz-border-radius: 0 0.1rem 0.1rem 0;
    -o-border-radius: 0 0.1rem 0.1rem 0;
    border-radius: 0 0.1rem 0.1rem 0;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(1)
    > label {
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
    font-weight: var(--fontWeight);
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(1)
    > span {
    font-size: 0.9rem !important;
    font-weight: var(--fontWeight);
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2) {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    flex-basis: 100%;
    background-color: #fff;
    padding: 0.5rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -o-border-radius: 2rem;
    border-radius: 0.5rem;
    color: var(--pttLinkColor);
    -webkit-box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
    -o-box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
    box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1) {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div:first-child {
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 50%;
    text-align: center;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li:nth-child(odd)
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div:first-child {
    border-right: solid 2px #eee;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div:last-child {
    margin-right: 0;
    flex-basis: 50%;
    text-align: center;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li:nth-child(even)
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div:last-child {
    border-left: solid 2px #eee;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div
    > label {
    font-size: 0.7rem !important;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div
    > span {
    font-weight: var(--fontWeight);
    font-size: 10px !important;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(2) {
    font-weight: var(--fontWeight);
    text-align: left;
    border-top: 0.2rem dotted #dfdedde0;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(2)
    > label {
    font-size: 0.7rem !important;
  }
}

/* Mobile */
@media only screen and (max-width: 790px) {
  .shipmentTrackingPreviewModal {
    position: relative;
    background-color: transparent;
    -webkit-backdrop-filter: blur(0);
    -moz-backdrop-filter: blur(0);
    -o-backdrop-filter: blur(0);
    backdrop-filter: blur(0);
  }

  .shipmentTrackingPreviewModal > .close {
    display: none;
  }

  .shipmentTrackingPreviewModalOpen {
  }

  .shipmentTracking {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }

  .shipmentTracking > .states {
    position: relative;
    width: 85%;
  }

  .shipmentTracking > .states > .state {
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
  }

  .shipmentTracking > .states > .state > .icons {
    flex-direction: column !important;
  }

  .shipmentTracking > .states > .state > .icons::after {
    display: none;
  }

  .shipmentTracking > .states > .state > .icons > .icon {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .shipmentTracking > .states > .state > .icons > .icon .activeIcon {
  }

  .shipmentTracking > .states > .state > .icons > .icon::after {
    top: 4.9rem;
    right: 1.5rem;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .shipmentTracking > .states > .state > .icons > .icon:last-child {
    margin-right: 0;
  }

  .shipmentTracking > .states > .state > .icons > .icon > svg {
    fill: var(--pttLinkColor);
    width: 2rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .icons
    > .icon:nth-child(odd)
    > .iconPath {
    top: 90%;
    left: 30%;
    transform: rotate(-90deg);
  }

  .shipmentTracking
    > .states
    > .state
    > .icons
    > .icon:nth-child(even)
    > .iconPath {
    top: 90%;
    left: -50%;
    transform: rotate(-90deg);
  }

  .shipmentTracking > .states > .state > .icons > .icon:last-child::before {
  }

  .shipmentTracking > .states > .state > .icons > .icon:last-child::after {
  }

  .shipmentTracking > .states > .state > .icons > .icon > .iconShadow {
  }

  .shipmentTracking > .states > .state > .progress {
    position: relative;
    width: 66%;
    margin-top: 0;
    box-shadow: none;
  }

  .shipmentTracking > .states > .state > .progress::before {
    position: absolute;
    top: 100%;
    left: 2rem;
    background-color: var(--pttYellowColor);
    content: "";
    width: 0.5rem;
    height: 3rem;
    z-index: -1;
  }

  .shipmentTracking > .states > .state > .progress::after {
    position: absolute;
    top: 100%;
    right: 2rem;
    background-color: var(--pttYellowColor);
    content: "";
    width: 0.5rem;
    height: 3rem;
    z-index: -1;
  }

  .shipmentTracking > .states > .state > .progress > h1 {
  }

  .shipmentTracking > .states > .state > .progress > h2 {
  }

  .shipmentTracking > .states > .state > .progress > .panel {
    flex-wrap: wrap;
    flex-direction: column;
    border-radius: 0 0 2rem 2rem !important;
    padding: 0;
  }

  .shipmentTracking > .states > .state > .progress > .panel > div {
    position: relative;
    padding: 1rem;
    border-bottom: dashed 2px #ccc;
  }

  .shipmentTracking > .states > .state > .progress > .panel > div:last-child {
    border-bottom: none;
    border-left: none;
  }

  .shipmentTracking > .states > .state > .progress > .panel > div::before {
    position: absolute;
    left: -0.55rem;
    bottom: -0.6rem;
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    width: 1rem;
    height: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    z-index: 1;
  }

  .shipmentTracking > .states > .state > .progress > .panel > div::after {
    position: absolute;
    right: -0.55rem;
    bottom: -0.6rem;
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    width: 1rem;
    height: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    z-index: 1;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .panel
    > div:last-child::before {
    display: none !important;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .panel
    > div:last-child::after {
    display: none !important;
  }

  .shipmentTracking > .states > .state > .progress > .panel > div > p {
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .panel
    > div
    > p:last-child {
  }

  .shipmentTracking > .states > .state > .progress > .panel > div > p > i {
  }

  .shipmentTracking > .states > .state > .progress > .panel > div > p > label {
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .panel
    > div
    > p
    > label
    > span:first-child {
    font-size: 0.7rem !important;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .panel
    > div
    > p
    > label
    > span:last-child {
  }

  .shipmentTracking > .states > .state > .progress > .deliveryInformation {
    display: none;
    /* display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 28%;
    left: -5%;
    background-color: #fff;
    padding: 0 1rem;
    width: 70%;
    height: auto;
    -webkit-border-radius: 0 0 0 2rem;
    -moz-border-radius: 0 0 0 2rem;
    -o-border-radius: 0 0 0 2rem;
    border-radius: 0 0 0 2rem;
    -webkit-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    -o-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    z-index: -1;
    -webkit-transition: left 0.3s ease-in-out;
    -moz-transition: left 0.3s ease-in-out;
    -o-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
    -webkit-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    -moz-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    -o-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    animation: deliveryInformationAnimation 1s ease 0s 2 normal none; */
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    width: 100%;
    height: 80%;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body
    > div {
    margin-bottom: 1rem;
    padding: 1rem;
    width: 50%;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body
    > div
    > h6 {
    margin-bottom: 0.2rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body
    > div
    > h6
    > i {
    margin-right: 0.2rem;
    border-bottom: solid 1px #f1f1f1;
    color: var(--pttLinkColor);
    font-size: 0.8rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body
    > div
    > h6
    > span {
    border-bottom: solid 1px #f1f1f1;
    color: var(--pttLinkColor);
    font-size: 0.7rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformation
    > .body
    > div
    > span {
    color: var(--pttLinkColor);
    font-size: 0.8rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformationOpenButton {
    display: none;
    /* display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    align-items: center;
    position: absolute;
    top: 15%;
    left: -5%;
    background-color: var(--pttLinkColor);
    width: 11rem;
    height: 2.5rem;
    padding-left: 1rem;
    -webkit-border-radius: 2rem 0 0 0;
    -moz-border-radius: 2rem 0 0 0;
    -o-border-radius: 2rem 0 0 0;
    border-radius: 2rem 0 0 0;
    color: #fff;
    -webkit-transition: left 0.3s ease-in-out;
    -moz-transition: left 0.3s ease-in-out;
    -o-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
    z-index: 1;
    cursor: pointer;
    -webkit-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    -moz-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    -o-animation: deliveryInformationAnimation 1s ease 0s 2 normal none;
    animation: deliveryInformationAnimation 1s ease 0s 2 normal none; */
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformationOpenButton
    > .arrow {
    position: absolute;
    right: -0.5rem;
    top: 50%;
    -webkit-transform: translateY(-50%) scaleX(1);
    -moz-transform: translateY(-50%) scaleX(1);
    -o-transform: translateY(-50%) scaleX(1);
    transform: translateY(-50%) scaleX(1);
    border-top: solid 1rem transparent;
    border-right: solid 1.25rem #ddd;
    border-left: solid 1.25rem transparent;
    border-bottom: solid 1rem transparent;
    transition: transform 0.5s, right 0.5s, border 1s ease-in-out;
    z-index: 1;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformationOpenButton
    > .arrowOpen {
    right: -3rem;
    border-right: solid 1.25rem #ddd;
    -webkit-transform: translateY(-50%) scaleX(-1);
    -moz-transform: translateY(-50%) scaleX(-1);
    -o-transform: translateY(-50%) scaleX(-1);
    transform: translateY(-50%) scaleX(-1);
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformationOpenButton
    > .arrowBack {
    position: absolute;
    right: -0.5rem;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    background-color: #555;
    width: 0.5rem;
    height: 2rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .deliveryInformationActive {
    left: -70%;
  }

  .shipmentTracking > .states > .state > .progress > .fees {
    display: none;
    /* position: absolute;
    top: 28%;
    right: -5%;
    background-color: #fff;
    padding: 0 1rem;
    width: 90%;
    height: auto;
    -webkit-border-radius: 0 0 2rem 0;
    -moz-border-radius: 0 0 2rem 0;
    -o-border-radius: 0 0 2rem 0;
    border-radius: 0 0 2rem 0;
    -webkit-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    -o-box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    box-shadow: 0 10px 35px -5px rgb(0, 0, 0, 0.2);
    z-index: -1;
    -webkit-transition: right 0.3s ease-in-out;
    -moz-transition: right 0.3s ease-in-out;
    -o-transition: right 0.3s ease-in-out;
    transition: right 0.3s ease-in-out;
    -webkit-animation: feesAnimation 1s ease 0s 2 normal none;
    -moz-animation: feesAnimation 1s ease 0s 2 normal none;
    -o-animation: feesAnimation 1s ease 0s 2 normal none;
    animation: feesAnimation 1s ease 0s 2 normal none; */
  }

  .shipmentTracking > .states > .state > .progress > .fees > .body {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    width: 100%;
    height: 80%;
  }

  .shipmentTracking > .states > .state > .progress > .fees > .body > div {
    margin-bottom: 1rem;
    padding: 1rem;
    width: 50%;
  }

  .shipmentTracking > .states > .state > .progress > .fees > .body > div > h6 {
    margin-bottom: 0.2rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .fees
    > .body
    > div
    > h6
    > i {
    margin-right: 0.2rem;
    border-bottom: solid 1px #f1f1f1;
    color: var(--pttLinkColor);
    font-size: 0.8rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .fees
    > .body
    > div
    > h6
    > span {
    border-bottom: solid 1px #f1f1f1;
    color: var(--pttLinkColor);
    font-size: 0.7rem;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .fees
    > .body
    > div
    > span {
    color: var(--pttLinkColor);
    font-size: 0.8rem;
  }

  .shipmentTracking > .states > .state > .progress > .feesOpenButton {
    display: none;
    /* display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 15%;
    right: -5%;
    background-color: var(--pttLinkColor);
    width: 11rem;
    height: 2.5rem;
    padding-right: 1rem;
    -webkit-border-radius: 0 2rem 0 0;
    -moz-border-radius: 0 2rem 0 0;
    -o-border-radius: 0 2rem 0 0;
    border-radius: 0 2rem 0 0;
    color: #fff;
    -webkit-transition: right 0.3s ease-in-out;
    -moz-transition: right 0.3s ease-in-out;
    -o-transition: right 0.3s ease-in-out;
    transition: right 0.3s ease-in-out;
    z-index: 1;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-animation: feesAnimation 1s ease 0s 2 normal none;
    -moz-animation: feesAnimation 1s ease 0s 2 normal none;
    -o-animation: feesAnimation 1s ease 0s 2 normal none;
    animation: feesAnimation 1s ease 0s 2 normal none; */
  }

  .shipmentTracking > .states > .state > .progress > .feesOpenButton > .arrow {
    position: absolute;
    left: -0.5rem;
    top: 50%;
    -webkit-transform: translateY(-50%) scaleX(1);
    -moz-transform: translateY(-50%) scaleX(1);
    -o-transform: translateY(-50%) scaleX(1);
    transform: translateY(-50%) scaleX(1);
    border-top: solid 1rem transparent;
    border-right: solid 1.25rem transparent;
    border-left: solid 1.25rem #ddd;
    border-bottom: solid 1rem transparent;
    -webkit-transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
    -moz-transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
    -o-transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
    transition: transform 0.5s, left 0.5s, border 1s ease-in-out;
    z-index: 1;
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .feesOpenButton
    > .arrowOpen {
    left: -3rem;
    border-left: solid 1.25rem #ddd;
    -webkit-transform: translateY(-50%) scaleX(-1);
    -moz-transform: translateY(-50%) scaleX(-1);
    -o-transform: translateY(-50%) scaleX(-1);
    transform: translateY(-50%) scaleX(-1);
  }

  .shipmentTracking
    > .states
    > .state
    > .progress
    > .feesOpenButton
    > .arrowBack {
    position: absolute;
    left: -0.5rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "";
    background-color: #555;
    width: 0.5rem;
    height: 2rem;
  }

  .shipmentTracking > .states > .state > .progress > .feesActive {
    right: -90%;
  }

  .shipmentTracking > .states > .history {
    position: relative;
    border-top: solid 0.5rem var(--pttYellowColor);
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -o-border-radius: 2rem;
    border-radius: 2rem;
  }

  .shipmentTracking > .states > .history > ul {
    display: block;
    max-height: 200px;
    padding: 0;
  }

  .shipmentTracking > .states > .history > ul::-webkit-scrollbar {
  }

  .shipmentTracking > .states > .history > ul::-webkit-scrollbar-track {
  }

  .shipmentTracking > .states > .history > ul::-webkit-scrollbar-thumb {
  }

  .shipmentTracking > .states > .history > ul > li {
    background-color: #fff;
    width: 100%;
    margin-bottom: 0;
    padding: 1rem !important;
    border-bottom: dashed 2px #ccc;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }

  .shipmentTracking > .states > .history > ul > li > i {
    display: none;
  }

  .shipmentTracking > .states > .history > ul > li > p {
    overflow: hidden;
  }

  .shipmentTracking > .states > .history > ul > li:last-child {
  }

  .shipmentTracking > .states > .history > ul > li:nth-child(odd) {
    padding: 0 0.5rem;
  }

  .shipmentTracking > .states > .history > ul > li:nth-child(even) {
    padding: 0 0.5rem;
  }

  .shipmentTracking > .states > .history > ul > li:nth-child(even) > i {
  }

  .shipmentTracking > .states > .history > ul > li:nth-child(even) > p {
  }

  .shipmentTracking > .states > .history > ul > li:nth-child(even) > p > label {
  }

  .shipmentTracking > .states > .history > ul > li > p > label {
    align-items: normal;
    flex-wrap: wrap;
    border: 0.2rem dotted #dfdedde0;
    border-radius: 0.5rem;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(1) {
    flex-basis: 100%;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li:nth-child(odd)
    > p
    > label
    > div:nth-child(1) {
    margin-right: 0;
    -webkit-border-radius: 0 0.1rem 0.1rem 0;
    -moz-border-radius: 0 0.1rem 0.1rem 0;
    -o-border-radius: 0 0.1rem 0.1rem 0;
    border-radius: 0 0.1rem 0.1rem 0;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li:nth-child(even)
    > p
    > label
    > div:nth-child(1) {
    margin-left: 0;
    -webkit-border-radius: 0 0.1rem 0.1rem 0;
    -moz-border-radius: 0 0.1rem 0.1rem 0;
    -o-border-radius: 0 0.1rem 0.1rem 0;
    border-radius: 0 0.1rem 0.1rem 0;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(1)
    > label {
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
    font-weight: var(--fontWeight);
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(1)
    > span {
    font-size: 0.9rem !important;
    font-weight: var(--fontWeight);
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2) {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    flex-basis: 100%;
    background-color: #fff;
    padding: 0.5rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -o-border-radius: 2rem;
    border-radius: 0.5rem;
    color: var(--pttLinkColor);
    -webkit-box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
    -o-box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
    box-shadow: 0 15px 40px -25px rgba(0, 0, 0, 0.4);
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1) {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div:first-child {
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    flex-basis: 50%;
    text-align: center;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li:nth-child(odd)
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div:first-child {
    border-right: solid 2px #eee;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div:last-child {
    margin-right: 0;
    flex-basis: 50%;
    text-align: center;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li:nth-child(even)
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div:last-child {
    border-left: solid 2px #eee;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div
    > label {
    font-size: 0.7rem !important;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(1)
    > div
    > span {
    font-weight: var(--fontWeight);
    font-size: 10px !important;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(2) {
    font-weight: var(--fontWeight);
    text-align: left;
    border-top: 0.2rem dotted #dfdedde0;
  }

  .shipmentTracking
    > .states
    > .history
    > ul
    > li
    > p
    > label
    > div:nth-child(2)
    > div:nth-child(2)
    > label {
    font-size: 0.7rem !important;
  }
}
