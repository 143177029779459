.buttonGroup {
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-bottom: 0.5rem;
  border: solid 1px #f1f1f1;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -o-border-radius: 1rem;
  border-radius: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  overflow: hidden;
}

.radioButton {
  position: relative;
  width: auto;
  margin: 0.2rem;
  padding: 0.1rem 0;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -o-border-radius: 0.4rem;
  border-radius: 0.4rem;
  color: var(--pttLinkColor);
  font-size: 0.8rem;
  font-weight: var(--fontWeight);
  text-align: center;
  cursor: pointer;
}

.radioButton > input {
  display: none;
}

.radioButton > span {
  position: absolute;
  top: 1px;
  left: -1.4rem;
  width: 1.1rem;
  height: 1.1rem;
  border: solid 1px var(--pttLinkColor);
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -o-border-radius: 1rem;
  border-radius: 1rem;
  text-align: center;
}

.radioButton > span > i {
  position: relative;
  top: -0.2rem;
  left: 0.05rem;
  color: var(--pttLinkColor);
  font-size: 0.7rem;
}

.radioButton > input:checked + span {
  background-color: var(--pttYellowColor);
  width: 1.1rem;
  height: 1.1rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: selected 2s ease-in-out 0s 1 normal none;
  -moz-animation: selected 2s ease-in-out 0s 1 normal none;
  -o-animation: selected 2s ease-in-out 0s 1 normal none;
  animation: selected 2s ease-in-out 0s 1 normal none;
}

.iExitProvince {
  background-color: #eee;
  width: 100%;
  height: 1.8rem;
  padding: 0 1rem;
  border: none;
  border: solid 1px #dfdfdf;
  -webkit-border-radius: 1rem 0 0 1rem;
  -moz-border-radius: 1rem 0 0 1rem;
  -o-border-radius: 1rem 0 0 1rem;
  border-radius: 1rem 0 0 1rem;
}

.iArrivalProvince {
  background-color: #eee;
  width: 100%;
  height: 1.8rem;
  padding: 0 1rem;
  border-top: solid 1px #dfdfdf;
  border-right: solid 1px #dfdfdf;
  border-left: none;
  border-bottom: solid 1px #dfdfdf;
}

.findButton {
  background-color: var(--pttYellowColor);
  width: 100% !important;
  height: 1.8rem !important;
  padding: 0;
  border: none;
  -webkit-border-radius: 0 1rem 1rem 0;
  -moz-border-radius: 0 1rem 1rem 0;
  -o-border-radius: 0 1rem 1rem 0;
  border-radius: 0 1rem 1rem 0;
  color: #fff;
}

.cityList {
  position: absolute;
  top: 1.8rem;
  right: 0;
  left: 0;
  background-color: #fff;
  width: auto;
  max-height: 200px;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 1;
}

.cityList > li {
  padding: 0.2rem 0.5rem;
  border-bottom: solid 1px #f1f1f1;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  cursor: pointer;
}

.cityList > li:last-child {
  border-bottom: none;
}

.result {
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  width: 100%;
  margin-top: 0.5rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -o-border-radius: 1rem;
  border-radius: 1rem;
  color: var(--pttLinkColor);
  font-size: 0.7rem;
  text-align: center;
}

.result::before {
  position: absolute;
  left: -0.4rem;
  background-color: #fff;
  content: "";
  width: 1rem;
  height: 1rem;
  border: solid 3px transparent;
  border-top: solid 3px var(--pttYellowColor);
  border-right: solid 3px var(--pttYellowColor);
  transform: rotate(45deg);
}

.result::after {
  position: absolute;
  right: -0.4rem;
  background-color: #fff;
  content: "";
  width: 1rem;
  height: 1rem;
  border: solid 3px transparent;
  border-top: solid 3px var(--pttYellowColor);
  border-right: solid 3px var(--pttYellowColor);
  transform: rotate(-130deg);
}

.result > i {
  display: inline-block;
  background-color: var(--pttYellowColor);
  height: 1.2rem !important;
  margin: 0 0.2rem;
  padding: 0 0.5rem;
  color: var(--pttLinkColor);
  font-style: normal;
  text-align: center;
}

@-webkit-keyframes selected {
  0% {
    top: -15px;
  }

  10% {
    top: 5px;
  }

  20% {
    top: -5px;
  }

  30% {
    top: 5px;
  }

  40% {
    top: -1px;
  }

  50% {
    top: 5px;
  }

  60% {
    top: 1px;
  }

  70% {
    top: 5px;
  }

  80% {
    top: 3px;
  }

  90% {
    top: 5px;
  }

  100% {
    top: 0px;
  }
}

@-moz-keyframes selected {
  0% {
    top: -15px;
  }

  10% {
    top: 5px;
  }

  20% {
    top: -5px;
  }

  30% {
    top: 5px;
  }

  40% {
    top: -1px;
  }

  50% {
    top: 5px;
  }

  60% {
    top: 1px;
  }

  70% {
    top: 5px;
  }

  80% {
    top: 3px;
  }

  90% {
    top: 5px;
  }

  100% {
    top: 0px;
  }
}

@-o-keyframes selected {
  0% {
    top: -15px;
  }

  10% {
    top: 5px;
  }

  20% {
    top: -5px;
  }

  30% {
    top: 5px;
  }

  40% {
    top: -1px;
  }

  50% {
    top: 5px;
  }

  60% {
    top: 1px;
  }

  70% {
    top: 5px;
  }

  80% {
    top: 3px;
  }

  90% {
    top: 5px;
  }

  100% {
    top: 0px;
  }
}

@keyframes selected {
  0% {
    top: -15px;
  }

  10% {
    top: 5px;
  }

  20% {
    top: -5px;
  }

  30% {
    top: 5px;
  }

  40% {
    top: -1px;
  }

  50% {
    top: 5px;
  }

  60% {
    top: 1px;
  }

  70% {
    top: 5px;
  }

  80% {
    top: 3px;
  }

  90% {
    top: 5px;
  }

  100% {
    top: 0px;
  }
}

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  /* .buttonGroup {
    justify-content: flex-start;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 0 0 2rem 1.8rem;
  }

  .buttonGroup::after {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    content: "";
  }

  .buttonGroup > label {
    margin-right: 3rem;
    color: #fff;
  }

  .radioButton {
  }

  .radioButton > input {
  }

  .radioButton > span {
    display: block;
    top: 0;
    left: -2rem;
    background-color: transparent;
    width: 1.5rem;
    height: 1.5rem;
    border: solid 1px #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
  }

  .radioButton > span > i {
    top: -0.2rem;
    color: #fff;
    line-height: 1.8rem;
  }

  .radioButton > input:checked + span {
    background-color: var(--pttYellowColor);
    border: none;
    width: 1.5rem;
    height: 1.5rem;
  }

  .radioButton > input:checked + span > i {
    top: -0.1rem;
    color: var(--pttLinkColor);
  }

  .iExitProvince {
    height: 3rem !important;
    margin-bottom: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
  }

  .iArrivalProvince {
    height: 3rem !important;
    margin-bottom: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
  }

  .findButton {
    height: 3rem !important;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
  }

  .findButton > i {
    font-size: 2rem !important;
  }

  .cityList {
    position: absolute;
    top: 3.3rem;
    right: 0;
    left: 0;
    background-color: #fff;
    width: auto;
    max-height: 200px;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    overflow-y: auto;
    z-index: 1;
  }

  .cityList > li {
    padding: 0.2rem 0.5rem;
    border-bottom: solid 1px #f1f1f1;
    color: var(--pttLinkColor);
    font-weight: var(--fontWeight);
    cursor: pointer;
  }

  .cityList > li:last-child {
    border-bottom: none;
  }

  .result {
    background-color: transparent;
    height: 2rem !important;
    margin-top: 1rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    border-radius: 0.5rem;
    color: #fff;
  }

  .result::before {
    display: none;
  }

  .result::after {
    display: none;
  }

  .result > i {
    position: relative;
    top: 0.1rem;
    background-color: var(--pttYellowColor);
    height: 1.5rem !important;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    border-radius: 0.5rem;
    line-height: 1.5rem;
  } */
}

/* Mobile */
@media only screen and (max-width: 790px) {
  .buttonGroup {
    justify-content: flex-start;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 0 0 2rem 1.8rem;
  }

  .buttonGroup::after {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    content: "";
  }

  .buttonGroup > label {
    margin-right: 3rem;
    color: #fff;
  }

  .radioButton {
  }

  .radioButton > input {
  }

  .radioButton > span {
    display: block;
    top: 0;
    left: -2rem;
    background-color: transparent;
    width: 1.5rem;
    height: 1.5rem;
    border: solid 1px #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
  }

  .radioButton > span > i {
    top: -0.2rem;
    color: #fff;
    line-height: 1.8rem;
  }

  .radioButton > input:checked + span {
    background-color: var(--pttYellowColor);
    border: none;
    width: 1.5rem;
    height: 1.5rem;
  }

  .radioButton > input:checked + span > i {
    top: -0.1rem;
    color: var(--pttLinkColor);
  }

  .iExitProvince {
    height: 3rem !important;
    margin-bottom: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
  }

  .iArrivalProvince {
    height: 3rem !important;
    margin-bottom: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
  }

  .findButton {
    height: 3rem !important;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
  }

  .findButton > i {
    font-size: 2rem !important;
  }

  .cityList {
    position: absolute;
    top: 3.3rem;
    right: 0;
    left: 0;
    background-color: #fff;
    width: auto;
    max-height: 200px;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }

  .cityList > li {
    padding: 0.2rem 0.5rem;
    border-bottom: solid 1px #f1f1f1;
    color: var(--pttLinkColor);
    font-weight: var(--fontWeight);
    cursor: pointer;
  }

  .cityList > li:last-child {
    border-bottom: none;
  }

  .result {
    background-color: transparent;
    height: 2rem !important;
    margin-top: 1rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    border-radius: 0.5rem;
    color: #fff;
  }

  .result::before {
    display: none;
  }

  .result::after {
    display: none;
  }

  .result > i {
    position: relative;
    top: 0.1rem;
    background-color: var(--pttYellowColor);
    height: 1.5rem !important;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    border-radius: 0.5rem;
    line-height: 1.5rem;
  }
}
