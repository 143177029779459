/* #region Start General */
.slider {
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.slider > .list {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.slider > .list > li {
  visibility: visible;
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.8s, visibility 0.8s ease-in-out;
  -moz-transition: opacity 0.8s, visibility 0.8s ease-in-out;
  -o-transition: opacity 0.8s, visibility 0.8s ease-in-out;
  transition: opacity 0.8s, visibility 0.8s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slider > .list > li > .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-object-position: 30%;
  -moz-object-position: 30%;
  -o-object-position: 30%;
  object-position: 30%;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* #endregion */
/* #region End General */

/* #region Start Title */
.slider > .list > li > .title {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, transparent 100%);
  width: 55%;
  padding: 0 0 0 38rem;
  z-index: 1;
}
.slider > .list > li > .title > h1 {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -o-border-radius: 2rem;
  border-radius: 2rem;
  color: #fff;
  -webkit-text-shadow: 2.5px 2.5px rgba(0, 0, 0, 0.3);
  -moz-text-shadow: 2.5px 2.5px rgba(0, 0, 0, 0.3);
  -o-text-shadow: 2.5px 2.5px rgba(0, 0, 0, 0.3);
  text-shadow: 2.5px 2.5px rgba(0, 0, 0, 0.3);
  font-size: 2.5rem;
  z-index: 1;
}
.slider > .list > li > .title > h2 {
  color: var(--pttYellowColor);
  font-size: 1.2rem;
}
/* #endregion */
/* End Title */

/* #region Start Button */
.slider > .buttons {
  position: absolute;
  top: 50%;
  left: 2rem;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
  -webkit-box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 1;
}
.slider > .buttons > li {
  position: relative;
  width: 4rem;
  height: 4rem;
  color: var(--pttLinkColor);
  text-align: center;
  line-height: 4rem;
  cursor: pointer;
}
.slider > .buttons > li:first-child {
  display: none;
}
.slider > .buttons > li:nth-child(2) {
  border-bottom: solid 2px #eee;
}
.slider > .buttons > li > i {
  position: relative;
  top: 0.2rem;
  font-size: 1.5rem;
}
.slider > .buttons > li:nth-child(2) > i {
  top: 0, 5rem !important;
}
/* #endregion */
/* End Button */

/* #region Start Dots */
.slider > .dots {
  position: absolute;
  top: 93%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.slider > .dots > li {
  display: inline-block;
  background-color: #fff;
  width: 1.5rem;
  height: 0.5rem;
  margin: 0 0.8rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0 0 5px rgb(254 200 39 / 30%);
  -moz-box-shadow: 0 0 0 5px rgb(254 200 39 / 30%);
  -o-box-shadow: 0 0 0 5px rgb(254 200 39 / 30%);
  box-shadow: 0 0 0 5px rgb(254 200 39 / 30%);
  -webkit-transition: background 0.2s, width 0.2s ease-in-out;
  -moz-transition: background 0.2s, width 0.2s ease-in-out;
  -o-transition: background 0.2s, width 0.2s ease-in-out;
  transition: background 0.2s, width 0.2s ease-in-out;
  cursor: pointer;
}
/* #endregion */
/* End Dots */

/* #region Start Slider Setting */
.show {
  visibility: visible !important;
  opacity: 1 !important;
}
.hidden {
  visibility: hidden !important;
  opacity: 0 !important;
}
.active {
  background-color: var(--pttYellowColor) !important;
  width: 0.5rem !important;
}
/* #endregion */
/* End Slider Settings */

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  /* #region Start General */

  .slider {
    height: 400px;
  }
  .slider > .list {
  }
  .slider > .list > li {
  }
  .slider > .list > li > .image img {
    -webkit-object-position: 65%;
    -moz-object-position: 65%;
    -o-object-position: 65%;
    object-position: 65%;
  }
  /* #endregion */
  /* #region End General */

  /* #region Start Title */
  .slider > .list > li > .title {
    justify-content: start;
    width: 50%;
    padding: 2rem;
  }
  .slider > .buttons > li:nth-child(1) {
    border-bottom: solid 2px #eee;
  }
  .slider > .list > li > .title > h1 {
    padding: 0;
    margin: 0 0 2rem 0;
    background-color: transparent;
    font-size: 1.5rem !important;
  }
  .slider > .list > li > .title > h1 > .begin {
    display: none;
  }
  .slider > .list > li > .title > h1 > .end {
    display: none;
  }
  .slider > .list > li > .title > h1::after {
    background-color: transparent;
    left: -0.5rem;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  .slider > .list > li > .title > h2 {
    font-size: 1rem !important;
  }
  /* #endregion */
  /* End Title */

  /* #region Start Button */
  .slider > .buttons {
    display: flex;
    flex-direction: column-reverse;
    display: -webkit-flex;
    display: -ms-flexbox;
    position: absolute;
    top: 70%;
    left: 2rem;
    background-color: transparent;
    box-shadow: none;
  }
  .slider > .buttons > li {
    position: relative;
    width: 3rem;
    height: 3rem;
    color: var(--pttLinkColor);
    text-align: center;
    line-height: 3rem;
    cursor: pointer;
    background-color: #eee;
  }
  .slider > .buttons > li > i {
    top: 0rem;
  }
  .slider > .buttons > li:first-child {
    display: block;
    background-color: var(--pttYellowColor);
    width: 14rem !important;
    margin-right: 1rem;
    border-radius: 1.5rem;
    padding: 0 1rem;

    border-bottom: none !important;
    color: var(--pttLinkColor);
    font-weight: var(--fontWeight);
    order: 0;
  }
  .slider > .buttons > li:first-child > i {
    margin-right: 0.5rem;
    -webkit-animation: vibrate-1 0.3s linear infinite both;
    animation: vibrate-1 0.3s linear infinite both;
  }
  .slider > .buttons > li:nth-child(2) {
    order: 1;
    border-top-right-radius: 1.5rem;
    border-bottom: 1px solid #ccc;
  }
  .slider > .buttons > li:nth-child(3) {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    border-bottom: none !important;
    margin-bottom: 3rem;
  }
  .buttonremove {
    opacity: 0.1;
  }
  /* #endregion */
  /* End Button */

  /* #region Start Dots */
  .slider > .dots {
    position: absolute;
    top: 84.5%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .slider > .dots > li {
    display: inline-block;
    background-color: #fff;
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 0.8rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    border-radius: 1rem;
    -webkit-box-shadow: 0 0 0 5px rgb(254 200 39 / 30%);
    -moz-box-shadow: 0 0 0 5px rgb(254 200 39 / 30%);
    -o-box-shadow: 0 0 0 5px rgb(254 200 39 / 30%);
    box-shadow: 0 0 0 5px rgb(254 200 39 / 30%);
    -webkit-transition: background 0.2s, width 0.2s ease-in-out;
    -moz-transition: background 0.2s, width 0.2s ease-in-out;
    -o-transition: background 0.2s, width 0.2s ease-in-out;
    transition: background 0.2s, width 0.2s ease-in-out;
  }

  /* #endregion */
  /* End Dots */

  /* #region Start Slider Setting */
  .show {
  }
  .hidden {
  }
  .active {
    width: 1rem !important;
  }
  /* #endregion */
  /* End Slider Settings */
}

.buttonslide {
  animation: ease-in-out 3s infinite;
}

@-webkit-keyframes ease-in-out {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}
@keyframes ease-in-out {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-1px, 1px);
    transform: translate(-1px, 1px);
  }

  60% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-1px, 1px);
    transform: translate(-1px, 1px);
  }

  60% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
/* Mobile */
@media only screen and (max-width: 790px) {
  /* #region Start General */
  .slider {
    display: none;
  }

  .slider > .list {
  }
  .slider > .list > li {
  }
  .slider > ul > li > img {
  }
  /* #endregion */
  /* #region End General */

  /* #region Start Title */
  .slider > .list > li > .title {
  }
  .slider > .list > li > .title > h1 {
  }
  .slider > .list > li > .title > h1 > .begin {
  }
  .slider > .list > li > .title > h1 > .end {
  }
  .slider > .list > li > .title > h1::after {
  }
  .slider > .list > li > .title > h2 {
  }
  /* #endregion */
  /* End Title */

  /* #region Start Button */
  .slider > .buttons {
  }
  .slider > .buttons > li {
  }
  .slider > .buttons > li:first-child {
  }
  .slider > .buttons > li:first-child > i {
  }
  .slider > .buttons > li:nth-child(2) {
  }
  .slider > .buttons > li > i {
  }
  /* #endregion */
  /* End Button */

  /* #region Start Dots */
  .slider > .dots {
  }
  .slider > .dots > li {
  }
  /* #endregion */
  /* End Dots */

  /* #region Start Slider Setting */
  .show {
  }
  .hidden {
  }
  .active {
  }
  /* #endregion */
  /* End Slider Settings */
}
