.shipmentTrackingCard {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: flex-start;
  position: absolute;
  top: 48%;
  left: 8rem;
  background-color: rgba(255, 255, 255, 1);
  width: 25rem;
  height: auto;
  -webkit-border-radius: 0 1.5rem 1.5rem 1.5rem;
  -moz-border-radius: 0 1.5rem 1.5rem 1.5rem;
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  -webkit-box-shadow: 0 5px 20px -15px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 20px -15px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 5px 20px -15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 20px -15px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.shipmentTrackingCard > .tabs {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  align-items: center;
  background-color: #f1f1f1;
  position: absolute;
  bottom: 100%;
  -webkit-border-radius: 1.5rem 1.5rem 0 0;
  -moz-border-radius: 1.5rem 1.5rem 0 0;
  -o-border-radius: 1.5rem 1.5rem 0 0;
  border-radius: 1.5rem 1.5rem 0 0;
}

.shipmentTrackingCard > .tabs > .menu {
  padding: 0.5rem 0.2rem;
  width: 5rem;
  border-right: solid 2px rgb(218, 218, 218);
  text-align: center;
}

.shipmentTrackingCard > .tabs > .menu:first-child {
  -webkit-border-radius: 1.5rem 0 0 0;
  -moz-border-radius: 1.5rem 0 0 0;
  -o-border-radius: 1.5rem 0 0 0;
  border-radius: 1.5rem 0 0 0;
}

.shipmentTrackingCard > .tabs > .menu:last-child {
  border-right: none;
  -webkit-border-radius: 0 1.5rem 0 0;
  -moz-border-radius: 0 1.5rem 0 0;
  -o-border-radius: 0 1.5rem 0 0;
  border-radius: 0 1.5rem 0 0;
}

.shipmentTrackingCard > .tabs > .menu > a {
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  font-size: 0.8rem;
}

.shipmentTrackingCard > .tabs > .menu > h1 {
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  font-size: 0.8rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.shipmentTrackingCard > .body {
  position: relative;
  width: 100%;
  padding: 1rem;
}

.shipmentTrackingCard > .body > .header {
  padding: 0 1rem 1rem 1rem;
}

.shipmentTrackingCard > .body > .header > h1 {
  margin-bottom: 0.3rem;
  color: var(--pttLinkColor);
  font-size: 1rem;
}

.shipmentTrackingCard > .body > .header > h2 {
  position: relative;

  color: var(--pttLinkColor);
}

.shipmentTrackingCard > .body > .error {
  padding: 0.5rem 0.5rem;
  border-radius: 0.7rem;
  color: var(--dangerBgColor);
}

.shipmentTrackingCard > .body > input {
  background-color: #eee;
  width: 100%;
  padding: 1rem;
  border: none;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  z-index: 1;
}

.shipmentTrackingCard > .body > button {
  position: absolute;
  right: 1rem;
  background-color: var(--pttYellowColor);
  height: 50px;
  width: 100px;
  border: none;
  -webkit-border-radius: 0 1rem 1rem 0;
  -moz-border-radius: 0 1rem 1rem 0;
  border-radius: 0 1rem 1rem 0;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
}
.shipmentTrackingCard > .body > .nearestBranchButton {
  position: relative;
  width: 150px;
  float: right;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  font-weight: var(--fontWeight);
  color: var(--pttLinkColor);
  border: none;
}
.shipmentTrackingCard > .body > button > i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: progressAnimation 1s ease-in-out infinite;
  -moz-animation: progressAnimation 1s ease-in-out infinite;
  -o-animation: progressAnimation 1s ease-in-out infinite;
  animation: progressAnimation 1s ease-in-out infinite;
}

.shipmentTrackingCard > .body > a {
  position: absolute;
  right: 1rem;
  background-color: var(--pttYellowColor);
  height: 48px;
  width: 100px;
  border: none;
  -webkit-border-radius: 0 1rem 1rem 0;
  -moz-border-radius: 0 1rem 1rem 0;
  border-radius: 0 1rem 1rem 0;
  color: #fff;
  font-weight: var(--fontWeight);
  line-height: 48px;
  text-align: center;
}

.shipmentTrackingCard > .body > a > i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: progressAnimation 1s ease-in-out infinite;
  -moz-animation: progressAnimation 1s ease-in-out infinite;
  -o-animation: progressAnimation 1s ease-in-out infinite;
  animation: progressAnimation 1s ease-in-out infinite;
}

/* #region Start mShipment Tracking */
.mShipmentTrackingCard {
  display: none;
}

/* #endregion */
/* End mShipment Tracking */

/* #region Start Slider Setting */
.activeMS {
  visibility: visible !important;
  opacity: 1 !important;
}

/* #endregion */
/* End Slider Settings */

@-webkit-keyframes progressAnimation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-moz-keyframes progressAnimation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-o-keyframes progressAnimation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes progressAnimation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  .shipmentTrackingCard {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 70%;
    left: 2rem;
    min-width: 35rem;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-top-left-radius: none;

    -webkit-transition: visibility 0.5s, opacity 0.5s, top 0.8s,
      width 1s ease-in-out;
    -moz-transition: visibility 0.5s, opacity 0.5s, top 0.8s,
      width 1s ease-in-out;
    -o-transition: visibility 0.5s, opacity 0.5s, top 0.8s, width 1s ease-in-out;
    transition: visibility 0.5s, opacity 0.5s, top 0.8s, width 1s ease-in-out;
  }
  .shipmentTrackingCard > .body {
    border-top-left-radius: none;
  }
  .shipmentTrackingCard > .tabs > .menu {
    padding: 0.8rem 0.4rem;
    width: 6rem;
    border-right: solid 2px rgb(218, 218, 218);
    text-align: center;
  }
  .shipmentTrackingCard::before {
    position: absolute;
    top: 100%;
    margin-left: 1.5rem;
    content: "";
    border-top: solid 1rem rgba(255, 255, 255, 0.4);
    border-right: solid 1rem transparent;
    border-left: solid 1rem transparent;
    border-bottom: solid 1rem transparent;
  }

  /* #region Start mShipment Tracking */
  .mShipmentTrackingCard {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 70%;
    left: 2rem;
    width: 25%;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 2rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    z-index: 1;
    -webkit-transition: visibility 0.5s, opacity 0.5s, top 0.8s,
      width 1s ease-in-out;
    -moz-transition: visibility 0.5s, opacity 0.5s, top 0.8s,
      width 1s ease-in-out;
    -o-transition: visibility 0.5s, opacity 0.5s, top 0.8s, width 1s ease-in-out;
    transition: visibility 0.5s, opacity 0.5s, top 0.8s, width 1s ease-in-out;
  }

  .mShipmentTrackingCard::before {
    position: absolute;
    top: 100%;
    content: "";
    border-top: solid 0.5rem rgba(255, 255, 255, 0.2);
    border-right: solid 0.5rem transparent;
    border-left: solid 0.5rem transparent;
    border-bottom: solid 0.5rem transparent;
  }

  .mShipmentTrackingCard > input {
    background-color: #eee;
    width: 100%;
    height: 3rem;
    padding: 0 7rem 0 0.8rem;
    border: none;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    z-index: 1;
  }
  .shipmentTrackingCard > .body > a {
    height: 55px;
  }
  .shipmentTrackingCard > .body > button {
    height: 55px;
  }

  .shipmentTrackingCard .buttonGroup {
    border: solid 1px #f1f1f1;
  }
  /* #endregion */
  /* End mShipment Tracking */

  .activeMS {
    top: 32%;
    width: 40%;
    min-width: 30rem;
    height: 46%;
  }
}
.formLink {
  margin-left: 0.8rem;
  font-size: 0.8rem;
  color: var(--pttLinkColor);
  margin-top: 0.5rem;
  cursor: pointer;
}
.formLink::before {
  position: absolute;
  top: 85%;
  left: 1rem;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  border-top: solid 5px transparent;
  border-right: solid 5px transparent;
  border-left: solid 5px var(--pttYellowColor);
  border-bottom: solid 5px transparent;
}

.formLink:hover {
  color: var(--pttYellowColor);
}
.feeFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.formLinkYd {
  position: absolute;
  top: 87%;
  left: 1rem;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  border-top: solid 5px transparent;
  border-right: solid 5px transparent;
  border-left: solid 5px var(--pttYellowColor);
  border-bottom: solid 5px transparent;
}
