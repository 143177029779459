/* #region Start General */
.buttonGroup {
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-bottom: 0.5rem;
  border: solid 1px #f1f1f1;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -o-border-radius: 1rem;
  border-radius: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  overflow: hidden;
}

.radioButton {
  position: relative;
  width: auto;
  margin: 0.2rem;
  padding: 0.1rem 0;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -o-border-radius: 0.4rem;
  border-radius: 0.4rem;
  color: var(--pttLinkColor);
  font-size: 0.8rem;
  font-weight: var(--fontWeight);
  text-align: center;
  cursor: pointer;
}

.radioButton > input {
  display: none;
}

.radioButton > span {
  position: absolute;
  top: 1px;
  left: -1.4rem;
  width: 1.1rem;
  height: 1.1rem;
  border: solid 1px var(--pttLinkColor);
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -o-border-radius: 1rem;
  border-radius: 1rem;
  text-align: center;
}

.radioButton > span > i {
  position: relative;
  top: -0.2rem;
  left: 0.05rem;
  color: var(--pttLinkColor);
  font-size: 0.7rem;
}

.radioButton > input:checked + span {
  background-color: var(--pttYellowColor);
  width: 1.1rem;
  height: 1.1rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: selected 2s ease-in-out 0s 1 normal none;
  -moz-animation: selected 2s ease-in-out 0s 1 normal none;
  -o-animation: selected 2s ease-in-out 0s 1 normal none;
  animation: selected 2s ease-in-out 0s 1 normal none;
}

.input {
  background-color: #eee;
  width: 100%;
  height: 1.8rem;
  padding: 0 1rem;
  border: none;
  border: solid 1px #dfdfdf;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -o-border-radius: 1rem;
  border-radius: 1rem;
}

.inputLeft {
  background-color: #eee;
  width: 100%;
  height: 1.8rem;
  padding: 0 0.1rem 0 0.5rem;
  border: none;
  border: solid 1px #dfdfdf;
  -webkit-border-radius: 1rem 0 0 1rem;
  -moz-border-radius: 1rem 0 0 1rem;
  -o-border-radius: 1rem 0 0 1rem;
  border-radius: 1rem 0 0 1rem;
}

.inputRight {
  background-color: #eee;
  width: 100%;
  height: 1.8rem;
  padding: 0 0.1rem 0 0.5rem;
  border-top: solid 1px #dfdfdf;
  border-right: solid 1px #dfdfdf;
  border-left: none;
  border-bottom: solid 1px #dfdfdf;
  -webkit-border-radius: 0 1rem 1rem 0;
  -moz-border-radius: 0 1rem 1rem 0;
  -o-border-radius: 0 1rem 1rem 0;
  border-radius: 0 1rem 1rem 0;
}

.findButton {
  float: right;
  background-color: var(--pttYellowColor);
  width: 4rem !important;
  height: 1.8rem !important;
  margin-top: 0.5rem;
  padding: 0;
  border: none;
  -webkit-border-radius: 0 1rem 1rem 0;
  -moz-border-radius: 0 1rem 1rem 0;
  -o-border-radius: 0 1rem 1rem 0;
  border-radius: 1rem;
  color: #fff;
}

.list {
  position: absolute;
  top: 1.4rem;
  right: 0;
  left: 0;
  background-color: #fff;
  max-height: 200px;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 1;
}

.list > li {
  padding: 0.2rem 0.5rem;
  border-bottom: solid 1px #f1f1f1;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  cursor: pointer;
}

.list > li:last-child {
  border-bottom: none;
}
.formLink {
  margin-left: 0.8rem;
  font-size: 0.8rem;
  color: var(--pttLinkColor);
  cursor: pointer;
}
.formLink::before {
  position: absolute;
  top: 87%;
  left: 1rem;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  border-top: solid 5px transparent;
  border-right: solid 5px transparent;
  border-left: solid 5px var(--pttYellowColor);
  border-bottom: solid 5px transparent;
}

.formLink:hover {
  color: var(--pttYellowColor);
}
.feeFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.formLinkYd {
  position: absolute;
  top: 87%;
  left: 1rem;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  border-top: solid 5px transparent;
  border-right: solid 5px transparent;
  border-left: solid 5px var(--pttYellowColor);
  border-bottom: solid 5px transparent;
}

.result {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;
  border: dashed 2px #f1f1f1;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -o-border-radius: 1rem;
  border-radius: 1rem;
}

.result > legend {
  position: absolute;
  top: -0.2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 0 1rem;
  color: var(--pttYellowColor);
}

.result > table {
  width: 100%;
}

.result > table > tbody > tr > td:nth-child(n + 2) {
  text-align: right;
}

.result > table > tbody > tr > td > label {
  display: block;
  font-size: 0.6rem;
  font-weight: var(--fontWeight);
}

.result > table > tbody > tr > td > span {
  display: block;
}

@-webkit-keyframes selected {
  0% {
    top: -15px;
  }

  10% {
    top: 5px;
  }

  20% {
    top: -5px;
  }

  30% {
    top: 5px;
  }

  40% {
    top: -1px;
  }

  50% {
    top: 5px;
  }

  60% {
    top: 1px;
  }

  70% {
    top: 5px;
  }

  80% {
    top: 3px;
  }

  90% {
    top: 5px;
  }

  100% {
    top: 0px;
  }
}

@-moz-keyframes selected {
  0% {
    top: -15px;
  }

  10% {
    top: 5px;
  }

  20% {
    top: -5px;
  }

  30% {
    top: 5px;
  }

  40% {
    top: -1px;
  }

  50% {
    top: 5px;
  }

  60% {
    top: 1px;
  }

  70% {
    top: 5px;
  }

  80% {
    top: 3px;
  }

  90% {
    top: 5px;
  }

  100% {
    top: 0px;
  }
}

@-o-keyframes selected {
  0% {
    top: -15px;
  }

  10% {
    top: 5px;
  }

  20% {
    top: -5px;
  }

  30% {
    top: 5px;
  }

  40% {
    top: -1px;
  }

  50% {
    top: 5px;
  }

  60% {
    top: 1px;
  }

  70% {
    top: 5px;
  }

  80% {
    top: 3px;
  }

  90% {
    top: 5px;
  }

  100% {
    top: 0px;
  }
}

@keyframes selected {
  0% {
    top: -15px;
  }

  10% {
    top: 5px;
  }

  20% {
    top: -5px;
  }

  30% {
    top: 5px;
  }

  40% {
    top: -1px;
  }

  50% {
    top: 5px;
  }

  60% {
    top: 1px;
  }

  70% {
    top: 5px;
  }

  80% {
    top: 3px;
  }

  90% {
    top: 5px;
  }

  100% {
    top: 0px;
  }
}

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  .result > legend {
    margin-top: 1rem;
    background-color: transparent;
  }
  .result > strong {
    margin-top: 1rem;
  }
  #domesticrow {
    flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
  }
  .result {
    width: 100%;
    height: 80%;
    padding: 0 0.5rem;
  }
}

/* Mobile */
@media only screen and (max-width: 790px) {
  .buttonGroup {
    justify-content: flex-start;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 0 0 2rem 1.8rem;
  }

  .buttonGroup::after {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    content: "";
  }

  .buttonGroup > label {
    margin-right: 3rem;
    color: #fff;
  }
  .formLink {
    justify-content: center;
    color: #f2f2f2;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .formLink:before {
    display: none;
  }
  .feeFooter {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .radioButton {
  }

  .radioButton > input {
  }

  .radioButton > span {
    display: block;
    top: 0;
    left: -2rem;
    background-color: transparent;
    width: 1.5rem;
    height: 1.5rem;
    border: solid 1px #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
  }

  .radioButton > span > i {
    top: -0.2rem;
    color: #fff;
    line-height: 1.8rem;
  }

  .radioButton > input:checked + span {
    background-color: var(--pttYellowColor);
    border: none;
    width: 1.5rem;
    height: 1.5rem;
  }

  .radioButton > input:checked + span > i {
    top: -0.1rem;
    color: var(--pttLinkColor);
  }

  .input {
    height: 3rem !important;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
  }

  .inputLeft {
    height: 3rem;
    margin-top: 0.5rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
  }

  .inputRight {
    height: 3rem;
    margin-top: 1rem;

    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
  }

  .findButton {
    float: initial;
    width: 100% !important;
    height: 3rem !important;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
  }

  .findButton > i {
    font-size: 2rem !important;
  }

  .list {
    top: 3.3rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }

  .list > li {
  }

  .list > li:last-child {
  }

  .result {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: none;
  }

  .result > legend {
    display: none;
  }

  .result > strong {
    margin-bottom: 1rem;
    color: #fff;
  }

  .result > table {
  }

  .result > table > tbody > tr > td:nth-child(n + 2) {
  }

  .result > table > tbody > tr > td > label {
    color: #fff;
  }

  .result > table > tbody > tr > td > span {
    color: #fff;
  }
}
